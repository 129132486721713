import React, {Component} from 'react'
import { withRouter } from 'react-router-dom';
import Me from '../../Assets/me.jpg';
import './About.scss';

class About extends Component {

    render () {
			return (
        <div className='about-holder'>
          <div className="contact-div">
            <h2>Get to know me</h2>
          </div>
          <div className='about-top'>
            <div className='about-picture'>
              <img src={Me} alt='Self Portrait' />
            </div>
            <div className="about-paragraph">
              <h2>Good developers know how to make life easier for those around them.</h2>
              <p>Jordan Rhodes is a full stack web developer based out of Orange County, CA. He graduated with honors from a Biola, a 4 year university. The following year he completed a 14 week intensive coding program where he accumulated 1000+ hours of coding and built 60+ mini web applications. He is a developer with a familiarity in each layer of web design; a mastery in many front end tools such as Javascript, React.js and Angular, as well as back end tools like Python and Node. Jordan has a genuine interest in all software technology.  He has a passion for creating innovative and engaging web sites, learning more about the craft of web development, and exploring new concepts in web design.</p>
            </div>
          </div>
          <div className='about-bottom'>
            <p>Jordan enjoys building websites from the back end to the front end. He excels at taking complex problems and coming up with clean, elegant solutions. Jordan has the ability to write clear, consistent and simple to use interfaces. He understands that users “just want things to work”. He can step back and look at a process that needs 8 clicks and 3 steps and get it down to one click. The feeling of creating something from scratch really motivates and drives the passion behind his work. Jordan is very task oriented and skilled at managing a project timeline. He will work tirelessly to create your ideal website in the shortest time possible and have it up and running for all your future customers to see.</p>
            <p>Jordan is a lifelong learner and would love to learn about your vision for your business to be able to personalize your website and make it reflect your values, goals and dreams.</p>
            <p>When he's not working on web development, Jordan enjoys traveling abroad, playing basketball, watching a good movie, or playing video games. </p>
          </div>
        </div>
			)
    }
}

export default withRouter(About);
