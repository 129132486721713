import PortfolioApi from '../../Api/index';
import * as actionTypes from './ActionTypes';


export const listDocs = (model) => {
  return dispatch => {
    PortfolioApi.listDocs(model)
    .then(res=>{
      console.log('all docs', res);
      dispatch({type: actionTypes.GOT_PROJECTS, projects: res.result})
    })
    .catch(err=>console.log(err))
  }
}

const uploadImage = (obj) => {
  PortfolioApi.uploadImage(obj)
  .then( results => {
    console.log('image upload results', results);
  })
  .catch( err => {
    console.log('error uploading image', err);
  })
}

export const createProject = (model, data) => {
  return dispatch => {
    if (data.images.length) {
      let promises = [];

      if (data.images.length < 2) {
        let obj = {
          image: data.images[0],
          name: data.name,
          number: 1
        }
        promises.push(uploadImage(obj));
        data.images = [1];
      } else {
        data.images.forEach( (image, index) => {
          let obj = {
            image: image,
            name: data.name,
            number: index + 1
          }
          promises.push(uploadImage(obj));
          data.images[index] = index + 1
        })
      }
      return Promise.all(promises)
      .then( results => {
        console.log('image results', results);
        PortfolioApi.createDoc(model, data)
        .then(res=>{
          console.log('response on creation', res);
          dispatch({type: actionTypes.CREATED_PROJECT, project: res.result})
        })
        .catch(err=>{
          console.log('[Creation Error]', err)
        })
      })
    }
  }
}

export const sendEmail = (form) => {
  return dispatch => {
    PortfolioApi.sendEmail(form)
    .then( res => {
      console.log('[sent email]', res);
    })
    .catch( err => {
      console.log('[sent email Error]', err)
    })
  }
}
