import React, {Component} from 'react'
import { withRouter, Link } from 'react-router-dom';
import { createContainer, randomTimeInterval, createLine, removeRain } from '../../Utility/Rain';

import './Dashboard.scss';

class Dashboard extends Component {

  state = {
    timer: ''
  }

  componentDidMount () {
    createContainer();
    this.setState({
      timer: setInterval(createLine, randomTimeInterval(100,200))
    })
  }

  componentWillUnmount () {
    clearInterval(this.state.timer);
    removeRain();
  }

  render () {
    return (
      <React.Fragment>
        <div className='dashboard-holder'>
          <div className='name-holder'>
            <h1><span className='first'>Jordan</span> <span className='last'>Rhodes</span></h1>
          </div>
          <div className='info-holder'>
            <p>Full Stack Web Developer</p>
          </div>
          <div className='body-holder'>
            <h2 className='clip-text'>Let's bring your ideas to <span className='life'>life</span></h2>
          </div>
          <div className='button-holder'>
            <Link to='/contact'><p className='dash-btn btn-5 purple'>Contact Me</p></Link>
            <Link to='/about'><p className='dash-btn btn-5 blue'>Learn More</p></Link>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Dashboard);
