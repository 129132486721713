import React, {Component} from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Form from '../../Components/Form/Form';
import * as actions from '../../Store/Actions/Index';

import './Add.scss';

class Add extends Component {

    state = {
      form: {
        name: '',
        url: '',
        techUsed: '',
        description: '',
        images: []
      }
    }

    inputChanged = (e, name) => {
      let copy = {...this.state.form};
      let val = e.target.value;
      copy[name] = val;
      this.setState({
        form: copy
      })
    }

    imageUploaded = event => {
      let copy = {...this.state.form};
      copy.images = event.target.files
      this.setState({
        form: copy
      })
    }

    submitForm = () => {
      if (this.state.form.name && this.state.form.description) {
        let copy = this.state.form;
        copy.techUsed = copy.techUsed.split(', ');
        this.props.createProject(copy);
      } else {
        console.log('missing fields');
      }
    }

    render () {
			return (
        <div className='add-holder'>
          <div className='contact-div'>
            <h2>Add a new project</h2>
          </div>
          <Form imageUploaded={this.imageUploaded} place='add' form={this.state.form} inputChanged={this.inputChanged} onSubmit={this.submitForm}/>
        </div>
			)
    }
}


const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch =>{
    return {
      createProject: (form) => dispatch(actions.createProject('project', form))
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Add));
