import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './Layout/Layout';
import {connect} from 'react-redux';

class App extends Component {

	componentDidMount() {
	}

	render() {
		return (
			<BrowserRouter>
				<div className="App">
					<Layout />
				</div>
			</BrowserRouter>
		);
	}
}

const mapStateToProps= state => {
	return {

	}
}

const mapDispatchToProps = dispatch => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
