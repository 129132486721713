const _URL = 'https://a3uo2y55gc.execute-api.us-west-1.amazonaws.com/Initial/';
const _EMAIL = 'https://2tedfwzp20.execute-api.us-west-2.amazonaws.com/Prod/';

module.exports = {
  serialize: (obj) => {
    let query = [];
    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        query.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return query.join('&');
  },
  uploadImage: (obj) => {
    let url = _URL + "image/" + obj.name + '/' + obj.number;
    let params = {
      method: "POST",
      headers: {
        "Accept": "multipart/form-data",
        "Content-Type": "multipart/form-data"
      },
      body: obj.image
    }
    return fetch(url, params)
      .then(res => res.json())
  },
  sendEmail: (form) => {
    let url = _EMAIL + "send";
    let formParams = {
      toEmails: ['jrhodesy4@gmail.com'],
      subject: 'Portfolio Inquiry',
      message: `${form.name} - ${form.email} --  Message: ${form.message}`
    }
    let params = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formParams)
    }
    return fetch(url, params)
      .then(res => {
        return res;
      })
      .catch(err => { 
        return err;
      });
  },
  listDocs: (model) => {
    let url = _URL + "list/" + model;
    let params = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    }
    return fetch(url, params)
      .then(res => res.json())
  },
  createDoc: (model, data) => {
    let url = _URL + "create/" + model;
    let params = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }
    return fetch(url, params)
      .then(res => res.json())
  },
  viewDoc: (model, parameter, query) => {
    let url = _URL + "filter/" + model + "?" + parameter + "=" + query;
    let params = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
    }
    return fetch(url, params)
      .then(res => res.json())
  },
  updateDoc: (model, data) => {
    // must contain payload in data obect with updated model as well as the tkn
    console.log('[update doc data]', data);
    let url = _URL + "update/" + model + '/' + data.payload._id;
    let params = {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data.payload)
    }
    return fetch(url, params)
      .then(res => res.json())
      .catch(err => console.log('[updateDoc Error]', err))
  },
  checkForReview: (data) => {
    let url = _URL + 'filter/' + data.model + "?user_id=" + data.userID + "&api_code=" + data.apiID;
    let params = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
    }
    return fetch(url, params)
      .then(res => res.json())
  },
  updateProfile: (auth, data) => {
    const url = _URL + 'image/profile/' + data.id
    const params = {
      method: "PUT",
      headers: {
        "Authorization": data.tkn,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data.payload)
    }
    return fetch(url, params)
      .then(res => res.json())
  },
  search: (model, query) => {
    const url = _URL + 'filter/' + model + '?' + module.exports.serialize(query)
    const params = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
    }
    return fetch(url, params)
      .then(res => res.json())
      .catch(err => console.log('[filter err]', err));
  },
  delete: (model, id, token) => {
    const url = _URL + 'delete/' + model + '/' + id;
    const params = {
      method: "DELETE",
      headers: {
        "Authorization": token,
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    }
    return fetch(url, params)
      .then(res => res.json())
      .catch(err => console.log('[delete err]', err));
  }
}
