export const randomTimeInterval = (min, max) => {
  let number = Math.random() * (max - min ) + min;
  return number;
}

export const createContainer = () => {
  console.log('creating container for lines');
  let newContainer = document.createElement('div');
  newContainer.className = 'lines';
  newContainer.id = 'lines';
  document.getElementById('bodyContainer').appendChild(newContainer);
}

export const createLine = () => {
  let container = document.getElementById('lines');
  if (container.children.length < 100) {
    console.log('creating line');
    let newLine = document.createElement('div');
    styleLineDiv(newLine);
    document.getElementById('lines').appendChild(newLine);
  }
}

export const styleLineDiv = div => {
  div.className = 'line';
  div.style.marginLeft = randomTimeInterval(1, 100) + '%';
  return div;
}

export const removeRain = () => {
  document.getElementById('lines').remove();
}
