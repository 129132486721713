import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';

import ProjectReducer from './Reducers/Projects';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;


const rootReducer = combineReducers({
  projects: ProjectReducer
})


const store = createStore(rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);



export default store;
