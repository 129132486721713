import React from 'react';
import './Buttons.scss';

const submit = (props) => {

  return (
    <div className={`button ${props.color}`} onClick={props.click}>
      <p>{props.title}</p>
    </div>
  )

}

export default submit;
