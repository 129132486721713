import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import './Navbar.scss';
import logo from '../../Assets/jr_logo.png';

class Navigation extends Component {

  state = {

  }

  componentDidMount () {

  }

  componentDidUpdate (prevProps, prevState) {
  }

  render () {
      return (
        <div className='navbar'>
          <NavLink className='home' exact to='/' activeClassName='active-dash'><img src={logo} alt='Logo' /></NavLink>
          <NavLink className='padding' to='/projects' activeClassName='active'>Projects</NavLink>
          <NavLink className='padding' to='/about' activeClassName='active'>About Me</NavLink>
          <NavLink className='padding' to='/contact' activeClassName='active'>Contact</NavLink>
        </div>
      )
  }
}

const mapStateToProps = state => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Navigation));
