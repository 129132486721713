import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
  projects: []
}

const setProjects = (state, action) => {
	return {
		...state,
		projects: action.projects,
	}
}

const createdProject = (state, action) => {
  let copy = [...state.projects];
	return {
		...state,
		projects: copy.push(action.project),
	}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case (actionTypes.GOT_PROJECTS) : return setProjects(state, action);
    case (actionTypes.CREATED_PROJECT) : return createdProject(state, action);
		default: return state;
	}
}


export default reducer;
