import React, {Component} from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Form from '../../Components/Form/Form';
import * as actions from '../../Store/Actions/Index';

import './Contact.scss';

class Contact extends Component {

    state = {
      sent: false,
      active: {
        name: false,
        email: false,
        message: false
      },
      form: {
        name: '',
        email: '',
        message: ''
      }
    }

    componentDidMount () {

    }

    componentDidUpdate (prevProps, prevState) {

    }

    blur = (e) => {
      const clicked = e.target.id;
      const copy = {...this.state};
      if (copy.form[clicked] !== '') {
        console.log(copy.form[clicked]);
        copy.active[clicked] = true;
      } else {
        copy.active[clicked] = false;
      }
      this.setState({
        copy
      })
    }

    focus = (e) => {
      const clicked = e.target ? e.target.id : e;
      document.getElementById(clicked).focus();
      const copy = {...this.state.active};
      copy[clicked] = true;
      this.setState({
        active: copy
      })
    }

    inputChanged = (e, name) => {
      let copy = {...this.state.form};
      copy[name] = e.target.value;
      this.setState({
        form: copy
      })
    }

    submitForm = () => {
      this.props.sendEmail(this.state.form);
      this.setState({
        sent: true
      })
    }

    render () {
			return (
        <div className='contact-holder'>

          <div className="contact-div">
            <h2>Get in touch</h2>
            <p>Whether you would like my services to make a project or website, get information on any of my projects, or just say hi, I would love to hear from you.</p>
            <p>Send me a message here, or shoot me an email at <a className='email-link' href="mailto:jrhodesy4@gmail.com">jrhodesy4@gmail.com</a></p>
          </div>

          <div className="info">
            { this.state.sent ? <div className='email-sent'><p>Thank you!</p><p>I will get back to you as soon as possible.</p></div> : <Form place='contact' blur={this.blur} focus={this.focus} active={this.state.active} form={this.state.form} inputChanged={this.inputChanged} onSubmit={this.submitForm}/>}
          </div>

        </div>
          )
    }
}


const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch =>{
    return {
      sendEmail: (form) => dispatch(actions.sendEmail(form))
    }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
