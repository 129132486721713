import React from 'react';
import './Footer.scss';

const footer = (props) => {

  return (
    <div className="footer">
      <p>© 2022 Jordan Rhodes</p>
      <div className="media-links">
        <a href="https://twitter.com/jo_rho_doe" rel='noopener noreferrer' target="_blank">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://www.linkedin.com/in/jordan-rhodes-6b3003147/" rel='noopener noreferrer' target="_blank">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href="https://github.com/jrhodesy4" rel='noopener noreferrer' target="_blank">
          <i className="fab fa-github"></i>
        </a>
      </div>
    </div>
  )

}

export default footer;
