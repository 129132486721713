import React from 'react';
import { Route } from 'react-router-dom';

import Dashboard from '../../Containers/Dashboard/Dashboard';
import About from '../../Containers/About/About';
import Contact from '../../Containers/Contact/Contact';
import Projects from '../../Containers/Projects/Projects';
import Add from '../../Containers/Add/Add';


const content = (props) => {

  return (
    <div className={props.className}>
        <Route path="/" exact component={Dashboard}/>
        <Route path="/about" exact component={About}/>
        <Route path="/projects" exact component={Projects}/>
        <Route path="/contact" exact component={Contact}/>
        <Route path="/add" exact component={Add}/>
    </div>
  )

}

export default content;
