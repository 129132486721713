import React, {Component} from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as authActions from '../../Store/Actions/Index';
import construction from '../../Assets/construction.jpg';

import projectsJSON from '../../Utility/projects.json';

import './Projects.scss';

class Projects extends Component {

    state = {
      hover: null,
      modalOpen: false,
      projects: [],
      singleProject: null
    }

    componentDidMount () {
      document.addEventListener('mousedown', this.closeModal, false);
      this.setState({
          projects: projectsJSON.projects
        })
      // if (this.props.projects.length) {
      //   this.setState({
      //     projects: this.props.projects
      //   })
      // } else {
      //   this.props.listDocs('project');
      // }
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.closeModal, false);
    }

    componentDidUpdate (prevProps, prevState) {
      // if (this.props.projects !== prevProps.projects) {
      //   this.setState({
      //     projects: this.props.projects
      //   })
      // }
    }

    openModal = project => {
      this.setState({
        modalOpen: true,
        singleProject: project
      })
    }

    closeModal = (e) => {
      if (e.target.classList.contains('modal-curtain')) {
        this.setState({
          modalOpen: false,
          singleProject: null
        })
      } else {
        return;
      }
    }

    setHover = project => {
      let temp = project ? project._id : null
      this.setState({
        hover: temp
      })
    }

    render () {
			return (
        <div ref={node => this.node = node} className='coming-soon-holder'>
          {this.state.modalOpen ? <div className='modal-curtain'></div> : null}
          <div className="contact-div">
            <h2>Get to know my work</h2>
          </div>
          {this.state.projects ?
            <div className='projects-div'>
              {this.state.projects.map( project => {
                return <div onMouseEnter={() => this.setHover(project)} onMouseLeave={() => this.setHover()} className='single-project' key={project._id}>
                  { this.state.hover === project._id ? <div className='curtain-div'></div> : null }
                  <div className='image-holder'>
                    <img src={ project.images.length ? `https://s3.us-west-1.amazonaws.com/myportfolioimages/${project.name}/${project.name}1.jpg` : construction } alt='Project Header' />
                  </div>
                  <p>{project.displayName}</p>
                  { this.state.hover === project._id ?
                    <div className='hover-div'>
                      { project.url ? <div className='hover-btn yellow'>
                        <a target='_blank' rel='noopener noreferrer' href={project.url}>Visit Site</a>
                      </div> : '' }
                      <div onClick={() => this.openModal(project)} className='hover-btn green'>
                        <p>Learn More</p>
                      </div>
                    </div>
                  : null }
                </div>
              })}
            </div>
          : null}
          { this.state.modalOpen ?
            <div className='single-modal'>
              <div className='image-holder'>
                <img src={ this.state.singleProject.images.length ? `https://s3.us-west-1.amazonaws.com/myportfolioimages/${this.state.singleProject.name}/${this.state.singleProject.name}1.jpg` : construction } alt='Project Header' />
              </div>
              <div className='info-holder'>
                <p className='modal-title'>{this.state.singleProject.name}</p>
                <a target='_blank' rel='noopener noreferrer' href={this.state.singleProject.url}>{this.state.singleProject.url}</a>
                <p>{this.state.singleProject.description}</p>
                <div className='tech-holder'>
                  {this.state.singleProject.techUsed.map( (tech, index) => {
                    return <p key={index} className='tech-btn'>{tech}</p>
                  })}
                </div>
              </div>
            </div>
          : null }
        </div>
			)
    }
}


const mapStateToProps = state => {
    return {
      projects: state.projects.projects
    }
}

const mapDispatchToProps = dispatch =>{
    return {
      listDocs: (model) => dispatch(authActions.listDocs(model))
    }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Projects));
