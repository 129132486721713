import React from 'react';
import './Form.scss';
import Submit from '../Buttons/Submit.js';

const form = (props) => {

  return (
    <div className="form">
      { props.place === 'add' ?
        <React.Fragment>
          <div className='add-project'>
            <input value={props.form.name} onChange={(e) => props.inputChanged(e, 'name')} type='text' placeholder='Name' />
            <input value={props.form.url} onChange={(e) => props.inputChanged(e, 'url')} type='text' placeholder='Url' />
            <input value={props.form.techUsed} onChange={(e) => props.inputChanged(e, 'techUsed')} type='text' placeholder='Tech Used' />
            <textarea value={props.form.description} onChange={(e) => props.inputChanged(e, 'description')} placeholder='Description'></textarea>
            <div>
              <input type='file' multiple={true} onChange={(e) => props.imageUploaded(e)}/>
            </div>
          </div>
          <Submit click={props.onSubmit} title='Create' color='blue'  />
        </React.Fragment>
      : null }
      { props.place === 'contact' ?
        <React.Fragment>
          <div className='inputs'>
            <div className="col-3 input-effect">
              <input value={props.form.name} onChange={(e) => props.inputChanged(e, 'name')} onBlur={(e) => props.blur(e)} onFocus={(e) => props.focus(e)} className={`effect-20 ${props.active.name ? 'has-content': ''}`} id="name" type="text" placeholder="" />
                <label onClick={(e) => props.focus('name')}>Name</label>
                <span className="focus-border">
                  <i></i>
                </span>
              </div>
              <div className="col-3 input-effect">
                <input value={props.form.email} onChange={(e) => props.inputChanged(e, 'email')} onBlur={(e) => props.blur(e)} onFocus={(e) => props.focus(e)} className={`effect-20 ${props.active.email ? 'has-content': ''}`} id="email" type="text" placeholder="" />
                  <label onClick={(e) => props.focus('email')}>Email</label>
                  <span className="focus-border">
                    <i></i>
                  </span>
                </div>
              </div>
              <div className='textarea-div'>
                <div className="col-3 input-effect">
                  <textarea value={props.form.message} onChange={(e) => props.inputChanged(e, 'message')} onBlur={(e) => props.blur(e)} onFocus={(e) => props.focus(e)} className={`effect-20 ${props.active.message ? 'has-content': ''}`} id="message" placeholder=''></textarea>
                    <label onClick={(e) => props.focus('message')}>Message</label>
                    <span className="focus-border">
                      <i></i>
                    </span>
                  </div>
                </div>
                <Submit click={props.onSubmit} title='Send' color='blue'  />
              </React.Fragment>
            : null }


    </div>
  )

}

export default form;
