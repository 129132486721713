import React, { Component } from 'react';
import Content from './Content/Content';
import Nav from '../Containers/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';
import './Layout.scss';


class Layout extends Component {

  render () {
    return (
      <div className="page-view" id='bodyContainer'>
        <Nav />
        <Content className='page-view--main-view'/>
        <Footer />
      </div>
    )
  }
}

export default Layout
